<template>
  <component :is="this.currentView" @onLogin="user => initializeApplication(user)"
    @onInitializationDone="initializationDone()" @onSelected="loadConfiguration(true)"
    @onLoadingDone="updateView('AppView')" />
</template>

<script>
import AppView from './AppView.vue';
import LoginView from './LoginView.vue';
import SelectView from './SelectView.vue';
import LoadingCrossComponent from './components/loading/LoadingCrossComponent.vue';
import store from './store/index.js';
import RestClient from './js/rest/RestClient.js';
import { settingsMixin } from './components/settings/SettingsMixin';
import UserConfiguration from './js/UserConfiguration';

export default {
  mixins: [settingsMixin],
  components: {
    AppView,
    LoginView,
    SelectView,
    LoadingCrossComponent
  },
  data() {
    return {
      currentView: "LoginView"
    }
  },
  methods: {
    initializeApplication: async function (user) {
      await this.saveUserToStore(user);
      store.dispatch("user/activateAutomaticTokenRefresh");
      console.log(store.getters["user/getIdToken"]);

      const restClient = new RestClient();

      const dbUser = await restClient.getUser();
      store.dispatch("user/setDatabaseUser", dbUser.data);

      let retry = 0;
      let configsLoadingFailure = true;
      do {
        const graveyards = await restClient.getGraveyards();

        if (graveyards == null) {
          retry++;
        } else {
          store.dispatch("user/setAvailableConfigurations", graveyards.data);
          retry = 10;
          configsLoadingFailure = false;

          // If user has access to only one graveyard, it will be loaded directly
          if (graveyards.data.length === 1) {
            store.dispatch("user/setCurrentConfiguration", graveyards.data[0]);
            await this.loadConfiguration();
          }
        }

        await new Promise(r => setTimeout(r, 1000));
      } while (retry < 10)

      if (configsLoadingFailure) {
        return console.log("Configs couldn't load");
      }

      // Loading finished
      store.dispatch("management/setConfigurationLoading", false);
    },
    initializationDone() {
      const configurations = store.getters["user/getAvailableConfigurations"];

      // Redirect to graveyard selection or app depending on number of graveyards user has access to
      if (configurations.length > 1) {
        this.updateView("SelectView");
      } else {
        this.updateView("AppView");
      }
    },
    loadConfiguration: async function (updateView) {
      if (updateView) {
        store.dispatch("management/setConfigurationLoading", true);
        this.updateView("LoadingCrossComponent");
      }

      //Setting up Application -> Load Configuration for Gravemanagement and save to store
      const restClient = new RestClient();

      // await restClient.getGraveyards()
      //   .then(response => store.state.graveyards = response.data);
      let retry = 0;
      let configLoadingFailure = true;
      do {
        await restClient.getConfiguration()
          .then(response => {
            if (response == null) {
              retry++;
            } else {
              store.dispatch("management/setConfiguration", response.data);
              retry = 10;
              configLoadingFailure = false;
            }
          }); // -> !!! Value is used in ConfigurationParser.js file
        await new Promise(r => setTimeout(r, 1000));
      } while (retry < 10)

      if (configLoadingFailure) {
        return console.log("Config couldn't load");
      }

      const userConfiguration = new UserConfiguration();

      // Load accent color
      this.setAccentColor(userConfiguration.getAccentColor());

      // Load font scale
      this.setFontScale(userConfiguration.getFontScale());

      //Setting up Application -> end
      if (updateView) {
        store.dispatch("management/setConfigurationLoading", false);
      }
    },
    updateView: function (view) {
      const currentPath = this.$router.options.history.state.current;
      const redirect = currentPath.includes("/login") ? "/" : currentPath;
      this.$router.push(redirect)
      this.currentView = view;
    },
    saveUserToStore: async function (user) {
      store.dispatch("user/setUser", user);
      store.dispatch("user/setIdToken", await user.getIdToken());
    }
  }
}
</script>

<style></style>