const user = {
    namespaced: true,
    state: () => ({
        user: null,
        idToken: null,
        dbUser: null,
        configurations: null,
        configuration: null,
    }),
    getters: {
        getUser(state) {
            return state.user;
        },
        getIdToken(state) {
            return state.idToken;
        },
        getDatabaseUser(state) {
            return state.dbUser;
        },
        getUserConfiguration(state) {
            if (state.dbUser == undefined) return null;
            return state.dbUser.config;
        },
        getAvailableConfigurations(state) {
            return state.configurations;
        },
        getCurrentConfiguration(state) {
            return state.configuration;
        },
        getEmail(state) {
            if (state.user == undefined) return null;
            return state.user.email;
        },
        getTokenExpirationTime(state) {
            return state.user.stsTokenManager.expirationTime;
        },
        getNextRefresh(state) {
            const tokenExpirationDate = new Date(state.user.stsTokenManager.expirationTime);
            const currentTime = new Date();
            const bufferInMillis = 60000;
            return tokenExpirationDate - currentTime - bufferInMillis;
        }
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        setIdToken(state, idToken) {
            state.idToken = idToken;
        },
        setDatabaseUser(state, dbUser) {
            state.dbUser = dbUser;
        },
        setUserConfiguration(state, configuration) {
            if (state.dbUser == undefined) return;
            state.dbUser.config = configuration;
        },
        setAvailableConfigurations(state, configurations) {
            state.configurations = configurations;
        },
        setCurrentConfiguration(state, configuration) {
            state.configuration = configuration;
        },
        activateAutomaticTokenRefresh(state, getters) {
            let timer = getters.getNextRefresh;
            setInterval(() => {
                const user = getters.getUser;
                user.getIdToken(true)
                    .then(idToken => {
                        state.idToken = idToken;
                        console.log(idToken)
                        timer = getters.getNextRefresh;
                    })
                    .catch(function (error) {
                        console.error(error);
                    });
            }, timer);
        }
    },
    actions: {
        setUser({ commit }, user) {
            commit('setUser', user);
        },
        setIdToken({ commit }, idToken) {
            commit('setIdToken', idToken);
        },
        setDatabaseUser({ commit }, dbUser) {
            commit('setDatabaseUser', dbUser);
        },
        setUserConfiguration({ commit }, configuration) {
            commit('setUserConfiguration', configuration);
        },
        setAvailableConfigurations({ commit }, configurations) {
            commit('setAvailableConfigurations', configurations);
        },
        setCurrentConfiguration({ commit }, configuration) {
            commit('setCurrentConfiguration', configuration);
        },
        activateAutomaticTokenRefresh({ commit, getters }) {
            commit('activateAutomaticTokenRefresh', getters);
        }
    },
}

export default user;